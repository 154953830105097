<script setup lang="ts">
import { VNode, computed, h, ref } from 'vue';
import { FormObject } from '@neanic/forms';

const { entry, children } = defineProps<{
    entry: FormObject,
    children?: VNode[]
}>();

const updates = ref(entry.updates);
entry.onUpdate(_ => updates.value++);

let component: VNode;
if (typeof entry.format === 'function')
{
    component = entry.format(entry, children);
}
else
{
    const tagName = (entry.format && entry.format !== 'object') 
        ? entry.format
        : 'div';

    component = h(tagName, entry.attr, children);
}

</script>

<template>

    <fieldset v-show="!entry.hidden" :class="entry.attr.class">

        <legend v-if="entry.title" :key="updates">
            {{entry.title}}
        </legend>

        <slot></slot>

    </fieldset>

</template>