<script setup lang="ts">
import { useSidebar } from '@neanic/io-vue';
import { useNeanic } from '@neanic/io';
import CreateWorkspace from './create-workspace.vue';
import SelectPlan from './select-plan.vue';
import { computed } from 'vue';
import { updates, update } from '@/providers/workspace-updater';

const neanic = useNeanic();
const sb = useSidebar();

const hasWorkspace = computed(() => {
    return typeof neanic.session?.workspaceId === 'string';
});

const hasPlan = computed(() => {
    return typeof neanic.session?.serviceTypes === 'string' 
        && neanic.session.serviceTypes.trim().length > 0;
});

const isRoot = computed(() => {
    return typeof neanic.session?.isRoot === 'string' 
        && neanic.session.isRoot === 'true';
});

const showNotification = computed(() => {
    if (isRoot.value) return false;
    return !hasWorkspace.value || !hasPlan.value;
});

async function createWorkspace()
{
    const feedback = await sb.open('New workspace', CreateWorkspace);
    feedback && update();
}

async function selectPlan()
{
    const feedback = await sb.open(`Select plan`, SelectPlan);
    feedback && update();
}
</script>

<template>

    <div>

        <section class="notification is-warning" :key="updates" v-if="showNotification">

            <template v-if="!hasWorkspace">
                <p class="pb-1">
                    Before accessing our APIs, you must first set up a workspace and select a service plan:
                    <button type="button" class="link" @click="() => createWorkspace()">
                        Setup workspace
                    </button>
                </p>
            </template>

            <template v-else-if="!hasPlan">
                <p class="pb-1">
                    Before accessing our APIs, you have to select a service plan:
                </p>
                <p>
                    <button type="button" class="link" @click="() => selectPlan()">
                        Show service plans
                    </button>
                </p>
            </template>

        </section>

    </div>

</template>