<script setup lang="ts">

</script>

<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
    viewBox="0 0 1462.4 334.75" fill="currentColor">

    <g id="Layer_3">
        <path class="st0" d="M167.54,258.24v76.27c-0.1,0.06-0.17,0.14-0.26,0.17s-0.19,0.09-0.26,0.06c-0.37-0.14-0.76-0.26-1.1-0.46
            c-27.12-15.72-54.22-31.44-81.4-47.21v-28.84L167.54,258.24L167.54,258.24z"/>
    </g>

    <g id="Layer_4">
        <path class="st0" d="M0,166.37v-82.7h250.69v82.7H0z"/>
    </g>

    <g id="Layer_5">
        <path class="st0" d="M84.49,252.17v-79.62h172.16V77.68H130.58C115.26,51.96,99.83,26.17,84.26,0.03c1.47-0.06,31.37,0,45.22,0
            c26.3,0,52.6-0.04,78.9,0.01c14.86,0.02,29.42,2.24,43.31,7.6c43.01,16.6,70.79,47.15,80.81,92.22
            c12.66,57.01-15.82,112.56-65.88,138.29c-18.59,9.55-38.39,14.05-59.28,14.03c-39.85-0.05-121.51-0.01-122.82-0.01L84.49,252.17z"
            />
    </g>

    <g id="Layer_2">
        <g>
            <path class="st0" d="M835.07,219.03v64.4h-30.9V98.25H835v9.85c1.35-0.88,2.22-1.42,3.06-2.01c10.18-7.05,21.45-10.95,33.82-11.1
                c19.97-0.24,36.36,7.67,49.22,22.8c24.24,28.53,21.24,73.12-6.55,97.96c-13.29,11.87-28.94,17.21-46.72,15.81
                c-10.84-0.86-20.72-4.63-29.67-10.82c-0.73-0.51-1.47-1.01-2.22-1.52c-0.07-0.05-0.19-0.05-0.87-0.19H835.07z M869.86,126.31
                c-6.46-0.04-12.38,1.78-17.79,5.24c-19.51,12.52-22.78,41.18-6.68,58.11c14.52,15.26,37.92,14.25,51.08-2.22
                c10.91-13.64,11.22-33.56,0.57-47.38C890.23,131.19,881.18,126.42,869.86,126.31z"/>
            <path class="st0" d="M453.24,227.33h-34.12V53.54c0,0,54.63-0.02,80.42,0c9.87,0,19.42,1.62,28.43,5.83
                c14.87,6.96,24.17,18.42,27.94,34.38c2.88,12.21,2.47,24.32-1.49,36.21c-5.41,16.23-16.89,26.35-32.82,31.76
                c-7.92,2.69-16.16,3.39-24.44,3.43c-13.44,0.07-43.92,0.02-43.92,0.02v62.16H453.24z M453.39,83.34v52.03c0,0,1.13,0,1.61,0
                c12.15,0,24.28,0.01,36.42-0.05c2.48-0.01,4.97-0.25,7.44-0.52c3.86-0.44,7.66-1.16,11.24-2.79c6.73-3.08,11.04-8.2,12.22-15.47
                c0.65-3.97,0.85-8.15,0.44-12.16c-1-9.67-6.4-16.14-15.73-18.92c-3.77-1.12-7.8-1.83-11.72-1.91c-13.43-0.27-26.87-0.17-40.3-0.21
                C454.51,83.34,453.39,83.34,453.39,83.34L453.39,83.34z"/>
            <path class="st0" d="M1195.86,174.92H1092.1c4.37,12.63,12.33,21.18,24.44,25.74c7.79,2.94,15.76,3.19,23.78,1.07
                c7.92-2.09,14.49-6.43,20.05-12.67c10.02,3.05,19.98,6.09,30.33,9.24c-0.78,1.3-1.38,2.39-2.08,3.41
                c-10.65,15.51-25.29,25.25-43.6,29.12c-32.97,6.99-62.74-9.48-76.38-35.9c-20.79-40.27,2.95-89.94,47.35-98.97
                c36.19-7.36,69.98,14.19,78.85,50.24c2.02,8.21,2.53,16.5,1.74,24.89c-0.05,0.6-0.16,1.18-0.26,1.77
                c-0.11,0.57-0.25,1.15-0.46,2.04V174.92z M1164.01,150.53c-0.29-0.98-0.44-1.74-0.71-2.45c-1.54-4.01-3.61-7.75-6.27-11.12
                c-4.42-5.62-9.97-9.65-16.89-11.66c-18.82-5.44-40.04,5.29-46.89,23.73c-0.16,0.42-0.17,0.9-0.29,1.51h71.06L1164.01,150.53z"/>
            <path class="st0" d="M787.95,163.59c0.27,38.03-31.4,69.13-69.35,68.83c-37.88-0.3-68.78-31.23-68.61-69.07
                c0.17-37.61,30.22-68.96,69.15-68.83C759.93,94.66,788.48,128.16,787.95,163.59z M719.05,126.42c-20.52,0-37.11,16.49-37.15,36.96
                s16.49,37.13,36.93,37.23c20.55,0.1,37.33-16.62,37.29-37.17C756.11,142.97,739.54,126.44,719.05,126.42z"/>
            <path class="st0" d="M981.76,84.74H924.3V53.76h149.03l0.11,30.98h-57.57v142.59h-34.09c-0.06-1.23,0-142.58,0-142.58
                L981.76,84.74z"/>
            <path class="st0" d="M1327.71,227.31h-36.36c-9.78-13.32-19.72-26.86-29.58-40.3c-0.27-0.02-0.37-0.04-0.46-0.02
                c-0.1,0-0.21,0.02-0.27,0.09c-0.21,0.21-0.42,0.41-0.61,0.66c-9.62,13.07-19.23,26.15-28.85,39.21c-0.06,0.07-0.15,0.14-0.22,0.2
                s-0.16,0.12-0.32,0.25h-35.38c-0.06-0.19-0.11-0.29-0.12-0.37c-0.01-0.09-0.03-0.21,0.02-0.29c0.26-0.42,0.51-0.85,0.8-1.25
                c15.47-21.13,30.94-42.25,46.4-63.38c0.05-0.07,0.06-0.19,0.21-0.72c-15.11-20.6-30.39-41.43-46.06-62.81
                c12.37-0.2,24.14-0.04,36.26-0.1c9.27,12.62,18.61,25.35,28.2,38.4c9.5-12.94,18.78-25.57,28.08-38.22h36.36
                c-15.57,21.23-30.84,42.05-46.21,63c16.02,21.84,31.94,43.55,48.16,65.65L1327.71,227.31z"/>
            <path class="st0" d="M1462.4,227.33h-36.36c-9.84-13.39-19.79-26.94-30.03-40.88c-10.11,13.76-20.04,27.28-30.02,40.88h-36.34
                c16.22-22.1,32.14-43.81,48.17-65.67c-15.35-20.91-30.63-41.75-46.2-62.98h36.34c9.17,12.48,18.51,25.21,28.04,38.19
                c9.49-12.93,18.8-25.6,28.11-38.29h35.59c0.06,0.12,0.12,0.21,0.15,0.31c0.02,0.09,0.05,0.19,0.02,0.27
                c-0.06,0.19-0.12,0.39-0.24,0.54c-15.07,20.55-30.14,41.11-45.43,61.96c15.92,21.7,31.85,43.42,48.18,65.67L1462.4,227.33z"/>
            <path class="st0" d="M605.62,227.4h-32.16V99.12h31.39v14.64c0,0,1.49-1.48,2.06-2.09c7.7-8.51,16.9-14.46,28.35-16.5
                c3.84-0.69,8.01-0.62,11.83-0.16v34.62c-1.06-0.35-2.06-0.69-3.06-1c-5.62-1.77-11.31-2.28-17.08-0.93
                c-11.29,2.64-19.72,12.16-20.98,23.69c-0.21,1.97-0.34,3.97-0.34,5.95c-0.02,22.1-0.01,44.2-0.01,66.29v3.8V227.4z"/>
        </g>
    </g>
</svg>
</template>