<script setup lang="ts">
import { VNode, h, ref } from 'vue';
import { FormArray } from '@neanic/forms';

const {entry, children} = defineProps<{
    entry: FormArray,
    children?: VNode[]
}>();

const updates = ref(entry.updates);
entry.onUpdate(_ => updates.value++);

let component: VNode;
if (typeof entry.format === 'function')
{
    component = entry.format(entry, children);
}
else
{
    component = h(entry.format || 'fieldset', entry.attr, children);
}

</script>

<template>

    <div v-show="!entry.hidden" class="form-control" :class="{'has-error': entry.invalid}">

        <label v-if="entry.title" :for="entry.id" :key="updates">
            {{entry.title}}
        </label>

        <component :is="component"></component>

        <p v-if="entry.description !== null && (entry.invalid || entry.description)" :class="entry.invalid ? 'error' : 'hint'">
            {{entry.errorMessage || entry.description }}
        </p>

    </div>

</template>