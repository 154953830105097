<script setup lang="ts">
import { ref } from 'vue';
import { RouterLink, useRouter } from 'vue-router';
import { Form, FormObject, Val } from '@neanic/forms';
import { useNeanic } from '@neanic/io-vue';
import { phoneValidator } from '@neanic/forms-vue';
import { OtpPayload } from './otp.vue';

const neanic = useNeanic();
const updateCard = ref(0);
const updateForm = ref(0);
const submitted = ref(false);
const notification = ref<{className?: string, message: string}>();
const router = useRouter();

const form = Form.object({
    name: Form.string({
        title: 'Your full name',
        validators: [Val.isRequired, Val.minLength(2), Val.maxLength(100)]
    }),
    company: Form.string({
        title: 'Company Name',
        description: 'Provide the name of your company or team (optional)',
        validators: [Val.maxLength(100)]
    }),
    email: Form.string({
        title: 'Email address',
        validators: [Val.isRequired, Val.email, Val.maxLength(100)]
    }),
    phone: Form.string({
        title: "Phone number",
        validators: [Val.isRequired, phoneValidator],
    }),
    password: Form.string({
        title: 'Preferred password',
        format: 'input:password',
        value: '',
        validators: [
            Val.isRequired, 
            Val.minLength(6)
        ]
    }),
});

async function submit()
{
    if (form.invalid) return;
    
    submitted.value = true;

    try {

        const {name, email, phone, password, ...additionalData} = form.value;
        const response = await neanic.executeAsync({
            identifier: 'account.register',
            payload: { name, email, phone, password, additionalData }
        });

        if (!response?.isSuccess)
        {
            throw new Error(response?.errorMessage || 'An error occured');
            updateForm.value++;
            return;
        }

        neanic.cache.set<OtpPayload>('otp', {
            username: form.value.email,
            onSuccess: 'profile'
        });

        await router.push({name: 'otp'});
    } catch (error: any) {
        notification.value = {className: 'is-error', message: error?.message || error};
    }
}

function tryAgain()
{
    form.e('email').reset();
    notification.value = undefined;
    submitted.value = false;
    updateCard.value++;
}

</script>

<template>

    <section>

        <h1>Register</h1>
        <p class="mb-5">Enter your information to register an account</p>

        <div class="card-body">

            <n-form :form="form" :key="updateForm" #="{message}" @submit.prevent="submit">

                <div v-if="notification?.message || message" class="notification" :class="notification?.className">
                    <p>{{ notification?.message || message }}</p>
                </div>

                <div class="flex flex-col space-y-4">

                    <div>
                        <button class="btn is-primary w-full">
                            Register
                        </button>
                    </div>

                    <div>
                        <span>Already registered?</span>
                        <router-link :to="{ name: 'login' }">
                            Login here
                        </router-link>
                    </div>

                </div>

            </n-form>

        </div>

    </section>

</template>
