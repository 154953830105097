<script setup lang="ts">
import { RouterView, useRouter } from 'vue-router';
import { useNeanic } from '@neanic/io';
import { useModal, useSidebar } from '@neanic/io-vue';
import NotificationComp from '@/components/notification.vue';
import LogoComp from '@/components/logo.vue';
import NavComponent from '@/components/nav.vue';
import SettingsComponent from '@/components/settings.vue';
import Nav from '@/components/nav.vue';
import { update } from '@/providers/workspace-updater';

const neanic = useNeanic();
const router = useRouter();
const sb = useSidebar();
const docsUrl = import.meta.env.VITE_DOCS_URL;

neanic.cache.watch('darkMode', darkMode => {
    if (darkMode) {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark');
    }
}, { immediate: true });

function toggleNav()
{
    if (sb.isVisible)
    {
        return sb.close();
    }

    sb.open('Proptexx', NavComponent);
}

function logout()
{
    return router.push({name: 'logout'});
}

async function showSettings(){
    const feedback = await sb.open('Settings', SettingsComponent)
    if (feedback) update();
}

</script>

<template>

    <section class="flex flex-row w-full h-full">

        <aside class="min-w-[250px] max-md:hidden flex flex-col bg-white">

            <div class="p-5 flex flex-row md:justify-center">
                <router-link to="/">
                    <LogoComp class="logo" />
                </router-link>
            </div>

            <hr>

            <Nav class="main" :key="neanic.token"></Nav>

        </aside>

        <div class="flex flex-col w-full h-full overflow-x-auto">

            <header class="flex flex-row justify-between p-5">

                <div>
                    <router-link to="/" class="md:hidden">
                        <LogoComp class="logo" />
                    </router-link>
                </div>

                <div class="p-1 bg-white rounded-full space-x-3 px-3">

                    <template v-if="neanic.isAuthenticated">

                        <a :href="docsUrl" target="_blank" class="link max-md:hidden">
                            <i class="fa fa-circle-info"></i>
                        </a>

                        <router-link :to="{name: 'inbox'}" class="link max-md:hidden">
                            <i class="fa fa-bell"></i>
                        </router-link>

                        <button type="button" class="link max-md:hidden" @click="logout">
                            <i class="fa fa-sign-out"></i>
                        </button>

                        <button type="button" class="link max-md:hidden" @click="showSettings">
                            <i class="fa fa-gear"></i>
                        </button>

                        <button type="button" class="link md:hidden" @click="toggleNav">
                            <i class="fa fa-rocket"></i>
                        </button>

                    </template>

                </div>

            </header>

            <main class="flex flex-col px-4 gap-2">
                <NotificationComp></NotificationComp>
                <router-view></router-view>
            </main>

            <n-panel-view />

        </div>

    </section>

</template>