import { VNode, createTextVNode, h, ref } from "vue";
import { FormArray, FormDefaults, FormEntry, FormObject } from "@neanic/forms";
import ArrayComponent from './array.component.vue'
import ControlComponent from './control.component.vue'
import ObjComponent from './object.component.vue';

export function formDefaultsForVue(formDefaults: typeof FormDefaults)
{
    formDefaults.addTemplate<FormObject, VNode>(
        entry => entry.entryType === 'object', _renderFormObject);

    formDefaults.addTemplate<FormArray, VNode>(
        entry => entry.entryType === 'array', _renderFormArray);

    formDefaults.addTemplate<FormEntry, VNode>(
        _ => true, _renderAnythingElse);
}

function _renderFormObject(entry: FormObject, children?: VNode[])
{
    return h(ObjComponent, {
        entry
    }, () => children);
}

function _renderFormArray(entry: FormArray, children?: VNode[])
{
    return h(ArrayComponent, {
        entry,
        children
    });
}

function _renderAnythingElse(entry: FormEntry, children?: VNode[])
{
    return h(ControlComponent, {
        entry,
        children
    });
}