<script setup lang="ts">
import { ref } from 'vue';
import { useNeanic, useSidebar } from '@neanic/io-vue';
import CreateWorkspaceComp from './create-workspace.vue';
import { updates, update } from '@/providers/workspace-updater';

const sb = useSidebar();
const neanic = useNeanic();
const isDarkMode = ref(neanic.cache.get('darkMode') || false);

const q = {
    identifier: 'account.GetWorkspaces',
    payload: {}
};

function toggleDarkMode(){
    isDarkMode.value = !isDarkMode.value;
    neanic.cache.set('darkMode', isDarkMode.value);
}

async function createWorkspace()
{
    const isSuccess = await sb.open('New workspace', CreateWorkspaceComp);
    if (!isSuccess) return;

    update();
}

async function select(workspaceId: string)
{
    console.log(workspaceId);
    await neanic.authAsync({
        workspace: { workspaceId }
    });

    update();
    sb.close();
}
</script>

<template>

    <section>

        <div class="mb-5 p-2 bg-gray-100 dark:bg-gray-800 rounded text-xs">
            <h3>{{ neanic.session?.fullName }}</h3>
            <p v-if="neanic.session?.isRoot">System Administrator</p>
        
            <div class="py-2">
                <div class="flex flex-row items-end justify-between">
                    <label for="useDarkMode">
                        Dark Mode
                    </label>
                    <div class="toggle-switch">
                        <input type="checkbox" id="useDarkMode" @change="toggleDarkMode" :checked="isDarkMode" />
                        <label for="useDarkMode"></label>
                    </div>
                </div>
            </div>
        </div>

        <n-query :config="q" :key="updates" #="workspaces">

            <div class="flex flex-row justify-between py-1">
                <h3>Workspaces</h3>
            <button type="button" class="link text-xs" @click="() => createWorkspace()">
                <i class="fa fa-edit"></i>
                New Workspace
            </button>

            </div>
            <div v-if="workspaces && workspaces.length > 0" class="overflow-x-auto">

                <table class="table is-striped">
                    <tbody>
                        <tr v-for="workspace of workspaces"
                            :key="workspace.id"
                            class="cursor-pointer"
                            @click="() => select(workspace.id)">
                            <td class="w-[22px]">
                                <i class="fa fa-star fa-sm text-red-800" v-if="neanic.session?.workspaceId && workspace.id === neanic.session.workspaceId"></i>
                            </td>
                            <td>{{ workspace.name }}</td>
                            <td>{{ workspace.roles }}</td>
                        </tr>
                    </tbody>
                </table>

            </div>

            <div v-else class="py-2">
                <p>You have no workspaces</p>
                <p>
                    <button type="button" class="link" @click="() => createWorkspace()">Click to get started</button>
                </p>
            </div>

        </n-query>

    </section>

</template>