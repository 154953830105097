<script setup lang="ts">
import { Form, Val } from '@neanic/forms';
import { useNeanic, useSidebar, useToast } from '@neanic/io-vue';

const neanic = useNeanic();
const sb = useSidebar();
const toast = useToast();

const form = Form.object({
    name: Form.string({
        title: 'Name your workspace',
        validators: [Val.isRequired, Val.minLength(4)]
    })
});

async function submit()
{
    if (form.invalid) return;

    const response = await neanic.executeAsync({
        identifier: 'workspace.CreateWorkspace',
        payload: form.value
    });

    if (!response?.isSuccess)
    {
        toast.error('Operation failed', response?.errorMessage);
        return;
    }

    toast.success('Workspace Ready');

    if (typeof response?.result?.workspaceId === 'string')
    {
        await neanic.authAsync({
            workspace: { workspaceId: response.result.workspaceId }
        });
    }
    
    sb.closeAll(true);
}

</script>

<template>

    <section>

        <p class="pb-4 text-xs">
            This will be the name of your Proptexx workspace. Choose something that your team will recognize
        </p>

        <n-form :form="form" @submit.prevent="submit">
        
            <div class="space-x-2">
    
                <button type="submit" class="btn is-primary">
                    <i class="fa fa-check"></i>
                    Submit
                </button>
    
                <button type="button" class="btn" @click="() => sb.close()">
                    <i class="fa fa-close"></i>
                    Close
                </button>
    
            </div>
        
        </n-form>

    </section>

</template>