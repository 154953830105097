<script setup lang="ts">
import { IQuery, useNeanic } from '@neanic/io';
import { useSidebar, useToast } from '@neanic/io-vue';

const neanic = useNeanic();
const toast = useToast();
const sb = useSidebar();

const q: IQuery = {
    identifier: 'workspace.getProductPlans',
    payload: {}
};

async function select(product: any)
{
    try {
        product._selected = true;

        const response = await neanic.executeAsync({
            identifier: 'workspace.Checkout',
            payload: {
                productId: product.id
            }
        });

        if (!response?.isSuccess)
        {
            throw Error('Unable to proceed to checkout');
        }

        if (response?.result?.redirectTo)
        {
            neanic.cache.set('redirectTo', location.href);
            location.href = response.result.redirectTo;
            return;
        }

        toast.success('Ok', 'The product has been appended to your workspace');

    } catch (error: any) {
        toast.error(error?.message || 'Unable to proceed to checkout');
        product._selected = false;
    }
}

</script>

<template>

    <section v-if="neanic.session?.workspaceName">

        <n-query :config="q" #="result">

            <template v-if="result && result.length > 0">

                <p class="text-sm pb-2 mb-2 border-b">Select which plan you would like to activate for your workspace <b>{{ neanic.session?.workspaceName }}</b> below:</p>

                <div class="bg-slate-100 p-2" v-for="p of result">

                    <div class="flex flex-row justify-between mb-1 border-b">
                        <h3>{{ p.title }}</h3>
                        <h4 class="text-pt-red-500">USD {{ p.priceAmount }}</h4>
                    </div>

                    <p>{{ p.description }}</p>

                    <p class="pt-4 pb-2">
                        <button :disabled="p._selected" type="button" class="btn" @click="() => select(p)">
                            <i class="fa fa-shopping-cart mr-0.5"></i>
                            {{ p._selected ? 'Wait..' : 'Subscribe' }}
                        </button>
                    </p>

                </div>

            </template>

            <template v-else>
                <p>We don't have any service plans at offer.</p>
                <p>Check back later</p>
                <p class="pt-3">
                    <button type="button" class="btn" @click="() => sb.close()">
                        <i class="fa fa-close"></i>
                        Close
                    </button>
                </p>
            </template>

        </n-query>

    </section>

</template>