<script setup lang="ts">
import { ref } from 'vue';
import Proptexx from '@/assets/proptexx.svg';
import Logo from '@/components/logo.vue';
// import { useNeanic } from '@neanic/io';
// import { useRouter } from 'vue-router';
// import StartLoginComp from './start-login.vue';
// import StartOtpComp from './start-otp.vue';

// enum StartStatus{
//     RequestEmail = 0,
//     OTP = 1
// }

// const status = ref(StartStatus.RequestEmail);
// const neanic = useNeanic();
const updates = ref(0);
// const notification = ref<{className?: string, message: string}>();

// neanic.cache.watch('auth', authValue =>
// {
//     if (authValue)
//     {
//         if (typeof authValue.email === 'string')
//         {
//             status.value = StartStatus.OTP;
//         }
//         else
//         {
//             status.value = StartStatus.RequestEmail;
//         }
//     }
//     else
//     {
//         status.value = StartStatus.RequestEmail;
//     }

//     updates.value++;

// }, { immediate: true });
</script>

<template>

    <section :key="updates" class="w-full h-full flex flex-row bg-white">

        <div class="flex-1 flex flex-row justify-center sm:pt-[100px]">
            <div class="w-full max-w-[400px] p-6">
                <router-link to="/" class="block w-40 pb-12">
                    <Logo />
                </router-link>
                <router-view></router-view>
            </div>
        </div>
 
        <div class="flex-1 max-sm:hidden bg-pt-red-500">
            <div class="start-background" :style="{'background-image': `url(${Proptexx})`}"></div>
        </div>

    </section>

    <n-panel-view />

</template>

<style lang="postcss" scoped>

    .start-background{
        @apply w-full h-full bg-no-repeat bg-right-bottom;
        opacity: 0.1;
        background-size: 400px;
    }

</style>