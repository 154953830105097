<script setup lang="ts">
import { FormEntry } from '@neanic/forms';
import { computed, h, ref, VNode } from 'vue';

const { entry, children } = defineProps<{
    entry: FormEntry;
    children?: VNode[];
}>();

const updates = ref(entry.updates);
entry.onUpdate(_ => updates.value++);

const componentFn = ({is, children, ...attr}: any) => {
    return (children && children.length > 0)
        ? h(is, attr, () => children)
        : h(is, attr);
};

const data: any = {
    ...entry.attr,
    children
};

function parseFormat(e: FormEntry, children?: VNode[])
{
    if (typeof e.format === 'function')
    {
        return e.format(e, children);
    }

    return e.format;
}

const format = parseFormat(entry, children);

let componentFormat: any[];
if (Array.isArray(format)) componentFormat = format;
else if (format === 'string') componentFormat = ['input', 'text'];
else if (format === 'number') componentFormat = ['input', 'number'];
else if (format === 'int32') componentFormat = ['input', 'number'];
else if (format === 'int64') componentFormat = ['input', 'number'];
else if (format === 'decimal') componentFormat = ['input', 'number'];
else if (format === 'boolean') componentFormat = ['input', 'checkbox'];
else if (format === 'object') componentFormat = ['textarea'];
else if (format === 'array') componentFormat = ['textarea'];
else if (typeof format === 'string' && format.includes(':')) componentFormat = format.split(':');
else componentFormat = [format];

if (typeof format !== 'object')
{
    data.type = componentFormat.length > 1 && componentFormat[1];
    data.value = entry.value;
    data.onInput = entry.attr.onInput || onInput;
}

function onInput(e: Event)
{
    entry.setValue(e);
}
</script>

<template>

    <div v-show="!entry.hidden" class="form-control" :class="{'has-error': entry.invalid}">

        <label v-if="entry.title" :for="entry.id" :key="updates">
            {{entry.title}}
        </label>

        <component-fn :is="componentFormat[0]" v-bind="data"></component-fn>

        <p v-if="entry.description !== null && (entry.invalid || entry.description)" :class="entry.invalid ? 'error' : 'hint'">
            {{entry.errorMessage || entry.description }}
        </p>

    </div>

</template>
