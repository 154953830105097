import { h } from "vue";
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import { useNeanic } from "@neanic/io-vue";
import StartComp from '@/pages/start.vue';
import LoginComp from '@/pages/login.vue';
import ForgotPasswordComp from '@/pages/forgot-password.vue';
import OtpComp from '@/pages/otp.vue';
import RegisterComp from '@/pages/register.vue';
import LogoutComp from '@/pages/logout.vue';
import AdminComp from '@/pages/admin.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'admin',
        beforeEnter: _guarder,
        meta: { isAuth: true },
        component: AdminComp,
        children: [{
            path: 'profile',
            children: [{
                path: '/',
                name: 'profile',
                component: () => import('@/pages/profile/account/index.vue')
            },{
                path: '/',
                name: 'set-password',
                component: () => import('@/pages/profile/account/index.vue'),
                meta: {
                    action: 'set-password'
                }
            },{
                path: 'message',
                name: 'message',
                component: () => import('@/pages/profile/message/index.vue'),
                redirect: { name: 'inbox' },
                children: [{
                    path: 'inbox',
                    name: 'inbox',
                    component: () => import('@/pages/profile/message/inbox.vue')
                },{
                    path: 'outbox',
                    name: 'outbox',
                    component: () => import('@/pages/profile/message/outbox.vue')
                }]
            }],
        },{
            path: 'order/:id',
            name: 'order',
            component: () => import('@/pages/order/view.vue')
        },{
            path: 'workspace',
            children: [
                {
                    path: '',
                    name: 'workspace-dashboard',
                    component: () => import('@/pages/workspace/index.vue')
                },
                {
                    path: 'members',
                    name: 'members',
                    component: () => import('@/pages/workspace/member/index.vue')
                },
                {
                    path: 'member/:id',
                    name: 'member',
                    component: () => import('@/pages/workspace/member/view.vue')
                },
                {
                    path: 'partner',
                    name: 'partner',
                    component: () => import('@/pages/workspace/partner/index.vue'),
                    children: [{
                        path: 'affiliate',
                        name: 'affiliates',
                        component: () => import('@/pages/workspace/partner/affiliate/index.vue')
                    },{
                        path: 'customer',
                        name: 'customers',
                        component: () => import('@/pages/workspace/partner/customer/index.vue')
                    },
                    // {
                    //     path: 'product',
                    //     name: 'products',
                    //     component: () => import('@/pages/workspace/partner/product/index.vue')
                    // },
                    // {
                    //     path: 'coupon',
                    //     name: 'coupons',
                    //     component: () => import('@/pages/workspace/partner/coupon/index.vue')
                    // },
                    {
                        path: 'service',
                        name: 'services',
                        component: () => import('@/pages/workspace/partner/service/index.vue')
                    }]
                },{
                    path: 'api',
                    name: 'api',
                    component: () => import('@/pages/workspace/api/index.vue'),
                    children: [{
                        path: 'get-started',
                        name: 'api-get-started',
                        component: () => import('@/pages/workspace/api/get-started.vue')
                    },{
                        path: 'access-key',
                        name: 'access-keys',
                        component: () => import('@/pages/workspace/api/access-key/index.vue')
                    },{
                        path: 'request',
                        name: 'requests',
                        component: () => import('@/pages/workspace/api/request/index.vue')
                    }]
                }, {
                    path: 'widget',
                    name: 'widget',
                    component: () => import('@/pages/workspace/widget/index.vue'),
                    children: [{
                        path: 'get-started',
                        name: 'widget-get-started',
                        component: () => import('@/pages/workspace/widget/get-started.vue')
                    },{
                        path: 'listing',
                        name: 'listings',
                        component: () => import('@/pages/workspace/widget/listing/index.vue')
                    },{
                        path: 'url-filter',
                        name: 'url-filters',
                        component: () => import('@/pages/workspace/widget/url-filter/index.vue')
                    },{
                        path: 'lead',
                        name: 'leads',
                        component: () => import('@/pages/workspace/widget/lead/index.vue')
                    }]
                }
            ]
        },{
            path: 'system',
            children: [
                {
                    path: '',
                    name: 'system',
                    component: () => import('@/pages/system/index.vue')
                },
                {
                    path: 'account',
                    name: 'accounts',
                    component: () => import('@/pages/system/account/index.vue')
                },
                {
                    path: 'account/:id',
                    name: 'account',
                    component: () => import('@/pages/system/account/view.vue')
                },
                {
                    path: 'workspace',
                    name: 'workspaces',
                    component: () => import('@/pages/system/workspace/index.vue')
                },
                {
                    path: 'workspace/:id',
                    name: 'workspace',
                    component: () => import('@/pages/system/workspace/view.vue')
                },
                {
                    path: 'product',
                    name: 'products',
                    component: () => import('@/pages/system/product/index.vue'),
                },
                {
                    path: 'product/:id',
                    name: 'product',
                    component: () => import('@/pages/system/product/view.vue'),
                },
                {
                    path: 'coupon/:id',
                    name: 'coupon',
                    component: () => import('@/pages/system/coupon/view.vue'),
                },
                {
                    path: 'coupon',
                    name: 'coupons',
                    component: () => import('@/pages/system/coupon/index.vue'),
                },
                {
                    path: 'service',
                    name: 'system-services',
                    component: () => import('@/pages/system/service/index.vue'),
                },
                {
                    path: 'service/:id',
                    name: 'service',
                    component: () => import('@/pages/system/service/view.vue'),
                },
                {
                    path: 'order',
                    name: 'system-orders',
                    component: () => import('@/pages/system/order/index.vue')
                },
                {
                    path: 'transaction',
                    name: 'system-transactions',
                    component: () => import('@/pages/system/transaction/index.vue')
                }
            ]
        }]
    },
    {
        path: '/',
        component: StartComp,
        children: [{
            path: 'login',
            name: 'login',
            component: LoginComp
        },{
            path: 'forgot-password',
            name: 'forgot-password',
            component: ForgotPasswordComp
        },{
            path: 'register',
            name: 'register',
            component: RegisterComp
        },{
            path: 'otp',
            name: 'otp',
            component: OtpComp
        }]
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutComp
    },
    {
        path: '/checkout',
        component: () => import('@/pages/checkout/index.vue'),
        children: [{
            path: 'success',
            component: () => import('@/pages/checkout/success.vue')
        },{
            path: 'canceled',
            component: () => import('@/pages/checkout/canceled.vue')
        }]
    },
    // {
    //     path: '/oauth',
    //     name: 'oauth',
    //     component: OAuthComp
    // },
    {
        path: '/:pathMatch(.*)',
        component: {
            render: () => h('div', 'Not found')
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

function _guarder(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext)
{
    if (to.meta.isAuth === true || to.matched.some((zz: any) => zz.meta.isAuth === true))
    {
        const neanic = useNeanic();

        if (neanic.isAuthenticated)
        {
            return next();
        }

        neanic.cache.reset();
        return next({name: 'login'});
    }

    return next();
}

router.isReady().then(() => {
    router.beforeEach(_guarder);
});

export default router;
