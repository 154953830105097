<script setup lang="ts">
import { useNeanic } from '@neanic/io-vue';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const neanic = useNeanic();
const router = useRouter();

onMounted(async ()=> {

    console.log(neanic.session);
    if (typeof neanic.session?.initiatorId === 'string')
    {
        await neanic.authAsync({
            'change-account': { accountId: neanic.session.initiatorId }
        });
    }
    else
    {
        neanic.setToken();
    }

    await router.push({path: '/'});
});

</script>

<template>

    <section class="px-3">

        <div class="card mx-auto max-w-lg">

            <div class="card-header">
                <h2>Logging out..</h2>
            </div>

            <div class="card-body">
                <p>Sit tight while we log you out of the system</p>
            </div>

        </div>

    </section>

</template>