<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { Form, Val } from '@neanic/forms';
import { useRouter } from 'vue-router';
import { useNeanic } from '@neanic/io';
import { otpControl } from '@neanic/forms-vue';
import LoaderComp from '@/components/loader.vue';

export interface OtpPayload {
    username: string;
    onSuccess?: string
}

const neanic = useNeanic();
const router = useRouter();
const isSending = ref(false);
const invalidOtp = ref(false);
const payload = ref<OtpPayload | undefined>(neanic.cache.get('otp'));

const form = Form.object({
    username: Form.string({
        title: null,
        format: 'input:hidden',
        value: payload.value?.username,
        validators: [Val.isRequired]
    }),
    password: Form.string({
        title: null,
        format: otpControl,
        class: 'form-control',
        validators: [Val.isRequired, Val.minLength(5)]
    })
});

onMounted(async () => {
    if (!payload.value)
    {
        return router.push('/');
    }
});

async function submit()
{
    try {
        
        if (form.invalid ) return;
        isSending.value = true;

        await neanic.authAsync({
            otp: form.value
        });

        if (!neanic.isAuthenticated){
            invalidOtp.value = true;
            isSending.value = false;   
            return;         
        }
        
        neanic.cache.del('otp');
        const route = payload.value?.onSuccess;

        if (typeof route === 'string' &&
            router.hasRoute(route))
        {
            return router.push({name: route});
        }

        return router.push({name: 'profile'});

    } catch (error) {
        isSending.value = false;
    }
}

async function resend()
{
    try {
        if (!payload.value)
        {
            return router.back();
        }

        isSending.value = true;
        const response = await neanic.executeAsync({
            identifier: 'account.resendOtp',
            payload: {
                ref: payload.value.username
            }
        });

        form.e('password').reset();
        invalidOtp.value = !response?.isSuccess === true;

    } catch (error) {
    }
    finally{
        isSending.value = false;
    }
}
</script>

<template>

    <section class="page">

        <template v-if="!invalidOtp">
        
            <div class="page-header">
                <div>
                    <h2>Great! Almost there..</h2>
                    <p>Please enter the authentication code sent by email to {{payload?.username}}</p>
                </div>
            </div>

            <div class="page-body">

                <n-form id="otpForm" :form="form" @submit.prevent="submit"></n-form>

            </div>

            <div class="page-footer">
                <button form="otpForm" type="submit" class="btn is-primary" :disabled="isSending">
                    Proceed
                </button>
            </div>

        </template>

        <template v-else>

            <div class="page-header">
                <div>
                    <h2>Ouch!</h2>
                    <p>The authentication code you provided is invalid. Try again..</p>
                </div>
            </div>

            <div class="page-body">
                <LoaderComp v-if="isSending"></LoaderComp>
            </div>

            <div class="page-footer">
                <button type="button" class="btn is-primary" :disabled="isSending" @click="resend">
                    Resend authentication code
                </button>
            </div>
        </template>

    </section>

</template>