<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useNeanic, useSidebar } from '@neanic/io-vue';
import { INavigationNode, getNav } from '@/providers/navigation.service';
import Tree from '@/components/tree/tree-view';

const { isChild } = defineProps<{ isChild?: boolean }>();
const sb = useSidebar();
const neanic = useNeanic();
const router = useRouter();
const parentTitle = computed(() => {
    if (!isChild) return;
    const r = router.currentRoute.value.name as string;
    console.log(r);
    return r;
});

async function navigate(navNode: INavigationNode)
{
    if (navNode.link)
    {
        router.push({ name: navNode.link });
    }
    else if (navNode.action){
        await navNode.action();
    }

    if (sb.isVisible)
    {
        sb.close();
    }
}

function isActiveRoute(item: INavigationNode)
{
    if (typeof item.onClick !== 'string') return false;

    const currentRoute = router.currentRoute.value;
    const current = item.onClick as string;

    // Check if the current route's name matches the item.onClick
    const isDirectMatch = currentRoute.matched.some(route => route.name === item.onClick);

    // Check if the current route's path starts with the item.onClick path
    const isNestedMatch = currentRoute.matched.some(route => {
        const itemPath = router.resolve({ name: current }).path;
        return currentRoute.path.startsWith(itemPath);
    });

    return isDirectMatch || isNestedMatch;
    // if (typeof item.onClick !== 'string') return false;
    // const names = router.currentRoute.value.matched
    //     .filter(x => typeof x.name === 'string')
    //     .map(x => x.name);

    // return names.includes(item.onClick);
}

</script>

<template>

    <nav>
        <Tree :nodes="getNav(neanic)" #="item: INavigationNode">

            <template v-if="item.onClick">
                <button v-if="typeof item.onClick === 'function'"
                    @click.prevent="() => navigate(item)"
                    class="link">
                    <i class="fa" :class="item.icon"></i>
                    {{ item.title }}
                </button>
                <router-link v-else 
                    :to="{name: item.onClick}"
                    @click.prevent="() => sb.close()">
                    <i class="fa" :class="item.icon"></i>
                    {{ item.title }}
                </router-link>
            </template>

            <template v-else>
                <h3 class="title">
                    {{ item.title }}
                </h3>
            </template>
        
        </Tree>
        <!--
            :class="{'selected': isActiveRoute(item)}"
            <div v-for="item of getNav(neanic, parentTitle)" 
            :key="item.title" 
            :class="{'selected': isActiveRoute(item)}">

            <template v-if="item.onClick">
                <button v-if="typeof item.onClick === 'function'"
                    @click.prevent="() => navigate(item)"
                    class="link">
                    <i class="fa" :class="item.icon"></i>
                    {{ item.title }}
                </button>
                <router-link v-else 
                    :to="{name: item.onClick}"
                    @click.prevent="() => sb.close()">
                    <i class="fa" :class="item.icon"></i>
                    {{ item.title }}
                </router-link>
            </template>
    
            <h4 v-else class="title">
                {{ item.title }}
            </h4>

        </div> -->

    </nav>

</template>