<script setup lang="ts">
import { ref } from 'vue';
import { RouterLink, useRouter } from 'vue-router';
import { Form, Val } from '@neanic/forms';
import { useModal, useNeanic, useToast } from '@neanic/io-vue';
import ConfirmModal from '@/components/confirm.modal.vue';

const neanic = useNeanic();
const router = useRouter();
const updateCard = ref(0);
const updateForm = ref(0);
const notification = ref<{className?: string, message: string}>();

const form = Form.object({
    email: Form.string({
        title: 'Your email address',
        validators: [Val.isRequired, Val.email, Val.maxLength(200)],
        focus: true
    }),
    password: Form.string({
        title: 'password',
        id: 'password',
        format: 'input:password',
        validators: [Val.isRequired, Val.minLength(6), Val.maxLength(200)],
        meta: { 
            focus: true
        }
    })
});

async function _submit()
{
    try {
        notification.value = undefined;
        if (form.invalid) return;

        const { email, password } = form.value;

        await neanic.authAsync({
            login: { authType: 'credentials', email, password }
        });

        if (!neanic.isAuthenticated) throw Error('Authentication failed. Try again..')

        const isRouted = await router.push({name: 'profile'});
        if (!isRouted) throw Error('Unable to route. Try again..');

    } catch (error: any) {
        notification.value = {className: 'is-error', message: error?.message || error};
    }
    finally{
        updateForm.value++;
    }
}

async function requestPassword()
{
    router.push({ name: 'forgot-password' });

    // const modal = useModal();
    // const title = 'Request New Password';
    // const strReceiver = form.value.username && form.value.username.include('@') ? ` for account ${form.value.username}?` : '?';
    // const message = `Would you like to request a new password${strReceiver}`;
    // const confirmed = await modal.open(title, ConfirmModal, true, { message })

    // if (confirmed)
    // {
    //     const response = await neanic.executeAsync({
    //         identifier: 'crm.account.requestPassword',
    //         payload: {
    //             username: form.value.username
    //         }
    //     });

    //     const toast = useToast();
    //     if (response?.isSuccess)
    //     {
    //         toast.success(title, 'Request OK');
    //     }
    //     else
    //     {
    //         toast.error(title, response?.errorMessage || 'An error occured. Try again or contact support');
    //     }
    // }
}

function tryAgain()
{
    form.value.reset();
    updateCard.value++;
}

function oauth(providerId: string)
{
    neanic.cache.set('oauth-providerId', providerId);
    router.push({ name: 'oauth' });
}

</script>

<template>

    <section>

        <h1>Sign In</h1>
        <p class="mb-5">Start by typing your email to sign in</p>

        <n-form :form="form" :key="form.updates" #="{message}" @submit.prevent="_submit">

            <div v-if="notification?.message || message" class="text-sm pb-3" :class="notification?.className || 'text-red-500'">
                <p>{{ notification?.message || message }}</p>
            </div>

            <div class="flex flex-col space-y-4">

                <div>
                    <button type="submit" class="btn is-primary w-full">
                        Sign In
                    </button>
                </div>

                <div>
                    <span>Not registered yet?</span>
                    <router-link :to="{ name: 'register' }" class="link">
                        Create an Account
                    </router-link>
                </div>

                <div>
                    <button type="button" class="link " @click="requestPassword">
                        Forgot your password?
                    </button>
                </div>

            </div>

        </n-form>

            <!-- <div class="py-4 flex items-center justify-center">
                <div class="flex-1 border-t border-gray-400"></div>
                <span class="px-4">OR</span>
                <div class="flex-1 border-t border-gray-400"></div>
            </div>

            <div class="text-center space-x-4">
                <button type="button" class="btn" @click="oauth('google')">Google</button>
                <button type="button" class="btn" @click="oauth('linkedin')">LinkedIn</button>
                <button type="button" class="btn" @click="oauth('microsoft')">Microsoft</button>
            </div> -->

        <!-- <div v-else>
            <p class="py-2">Wait..</p>
            <p>
                <button type="button" class="link" @click="tryAgain">Try again</button>
            </p>
        </div> -->

    </section>

</template>