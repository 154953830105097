<script setup lang="ts">
import { Form, Val } from '@neanic/forms';
import { useNeanic } from '@neanic/io';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import OtpComp, { OtpPayload } from '@/pages/otp.vue';
import SetPasswordComp from '@/pages/set-password.vue';

const neanic = useNeanic();
const router = useRouter();
const step = ref(0);
const notification = ref<{className?: string, message: string}>();
const form = Form.object({
    username: Form.string({
        title: 'Your email address',
        validators: [Val.isRequired, Val.email, Val.maxLength(200)],
        focus: true
    })
});

async function submit()
{
    notification.value = undefined;

    if (form.invalid) return;

    const response = await neanic.executeAsync({
        identifier: 'account.resetPassword',
        payload: form.value
    });

    if (!response?.isSuccess)
    {
        notification.value = { message: response?.errorMessage || 'Unknown email' };
        return;
    }

    neanic.cache.set<OtpPayload>('otp', {
        username: form.value.username,
        onSuccess: 'set-password'
    });

    step.value = 1;
}
</script>

<template>

    <section>
        
        <template v-if="step === 1">
            <OtpComp></OtpComp>
        </template>

        <template v-else>

            <h1>Reset password</h1>
            <p class="mb-5">Submit the email address you used to sign up and receive your authentication code</p>

            <n-form :form="form" :key="form.updates" #="{message}" @submit.prevent="submit">

                <div v-if="notification?.message || message" class="text-sm pb-3" :class="notification?.className || 'text-red-500'">
                    <p>{{ notification?.message || message }}</p>
                </div>

                <div class="flex flex-col space-y-4">

                    <div>
                        <button type="submit" class="btn is-primary w-full">
                            Reset Password
                        </button>
                    </div>

                </div>

            </n-form>

        </template>

    </section>

</template>