import './index.pcss';
import '@fortawesome/fontawesome-free/css/all.css';
import { NeanicClientOptions } from '@neanic/io';
import { createNeanicVue } from '@neanic/io-vue';
// import { formDefaultsForVue } from '@neanic/forms-vue';
import router from './router';
import { FormDefaults } from '@neanic/forms';
import { RouterView } from 'vue-router';
import { formDefaultsForVue } from './components/form/form-defaults';

formDefaultsForVue(FormDefaults);

const neanicOptions: NeanicClientOptions = {
    baseUrl: import.meta.env.VITE_SERVER_URL,
    apiKey: import.meta.env.VITE_SERVER_KEY,
    authenticatedResolver: 'accountId',
    cache: {
        onInit: () => {
            const data = localStorage.getItem('_proptexx');
            return typeof data === 'string' ? JSON.parse(data) : {};
        },
        onChange: (storage) => {
            localStorage.setItem('_proptexx', JSON.stringify(storage));
        }
    }
};

const app = createNeanicVue({
    rootComponent: RouterView,
    neanicOptions,
    router
});

app.mount('#app');
