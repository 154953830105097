import { h, SetupContext, VNode } from "vue";

export interface ITreeNode<T extends ITreeNode<T>> {
    title: string;
    children?: T[];
    [key: string]: any;
}

export interface TreeViewProps
{
    nodes: ITreeNode<any>[];
    collapsable?: boolean;
}

export default (props: TreeViewProps, { slots, attrs }: SetupContext) => {

    if (typeof props !== 'object')
    {
        return h('div', 'Expected the config attribute to be of type ITreeNode[]');
    }

    const tree = new TreeView({
        nodes: props.nodes,
        collapsable: props.collapsable,
        renderFn: slots.default || undefined
    });

    return tree.renderTree();
};

export interface TreeViewConfig
{
    nodes: ITreeNode<any>[];
    collapsable?: boolean;
    renderFn?: (node: ITreeNode<any>, level: number) => any;
}

class TreeView
{
    private _nodes: ITreeNode<any>[];
    private _collapsable: boolean;
    private _renderFn: (node: ITreeNode<any>, level: number) => any;

    constructor(config: TreeViewConfig)
    {
        this._nodes = config.nodes;
        this._collapsable = config.collapsable === true;
        this._renderFn = config.renderFn || ((n, l) => n.name);
    }

    public renderTree(): VNode | undefined
    {
        const isOpen = !this._collapsable;
        return this._renderChildren(this._nodes, isOpen, 0);
    }

    private _isCircularReference(node: ITreeNode<any>, ancestry: ITreeNode<any>[]): boolean {
        return ancestry.includes(node);
    }

    private _renderChildren(nodes: ITreeNode<any>[], isOpen: boolean, level: number, ancestry: ITreeNode<any>[] = []): VNode | undefined
    {
        if (typeof nodes !== 'object' || nodes.length <= 0)
        {
            return undefined;
        }

        level++;
    
        let children = [];
        for (let node of nodes)
        {
            // Check if the node is a circular reference
            if (this._isCircularReference(node, ancestry)) {
                node.circular = true;
                // Only render the node, but not its children
                children.push(h('li', this._renderFn(node, level)));
                continue;
            }
            const isOpenChild = !this._collapsable || node.__isOpen === true;
            const child = h('li', [
                this._renderFn(node, level),
                node.children && this._renderChildren(node.children, isOpenChild, level, [...ancestry, node])
            ]);
    
            children.push(child);
        }
    
        level--;
    
        return h('ul', {
            class: { open: isOpen }
        }, children);
    }
}
