import { useNeanic } from "@neanic/io-vue";
import { computed, ref } from "vue";

const _updates = ref(0);
const neanic = useNeanic();

export const updates = computed(() => {
    return `${neanic?.session?.workspaceId}_${_updates.value}`;
});

export function update(reset = false)
{
    const v = _updates.value;
    _updates.value = reset === true ? 0 : v + 1;
}